/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '生活助手-广州之兰科技有限公司',
  // 网站标题
  siteTitle: '生活助手 - 您身边的生活小管家',
  // 网站加载时显示的内容
  siteLoading: '',
  // 网站描述
  siteDescription:
    '生活助手(haoshenghou.com)是包含各种优惠生活常用导航网站，致力于将优惠的生活资讯传递传播工作！',
  // 网站关键字
  siteKeywords:
    '生活导航,惠生活助手,生活资讯',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/about'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: '粤ICP备2021092310号-5'
}
